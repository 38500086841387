import { Project } from "platformsh-client";

export const OrganizationProjectStatusesTypes = {
  active: "active",
  deleted: "deleted",
  provisioning: "provisioning",
  provisioningFailure: "provisioning failure",
  suspended: "suspended"
} as const;

export interface OrganizationProject {
  id: string;
  organization_id: string;
  subscription_id: string;
  region: string;
  title: string;
  type: string;
  plan: string;
  access_migration_status: string; // TODO - what are the possible values?
  status: (typeof OrganizationProjectStatusesTypes)[keyof typeof OrganizationProjectStatusesTypes];
  vendor: string;
  created_at: string;
  updated_at: string;
  subscribe: Project["subscribe"];
  _links: {
    // Can be used to check if the current user has access to the project
    api?: { href: string };
    self: { href: string };
    update?: { href: string };
    delete?: { href: string };
    subscription: { href: string };
  };
}

export type OrganizationRefProject = {
  created_at: string;
  id: string;
  organization_id: string;
  region: string;
  title: string;
  updated_at: string;
};

export type OrganizationProjectResponse = Record<
  string,
  // The api can return null in some situations on staging
  OrganizationRefProject | null
>;

export interface OrganizationProjectsList {
  count: number;
  facets: {
    plans?: Record<string, string>;
  };
  items: OrganizationProject[];
  _links: {
    previous?: {
      href: string;
    };
    next?: {
      href: string;
    };
  };
}
