import { useCallback, useEffect } from "react";

import { useParams } from "react-router-dom";

import { OrganizationProject } from "Api/organizations/projects/OrganizationProjects.types";
import { useAppDispatch, useAppSelector } from "Store/hooks";

import { endProvisioningPulling, startProvisioningPulling } from "..";
import {
  loadingOrganizationProjectsSelector,
  organizationProjectProvisioningPullingStarted,
  organizationProvisioningFailureProject,
  organizationProvisioningProject
} from "../projects.selectors";
import { getProject, getProjects } from "../thunks";

export default function useProvisioningPuller(options?: {
  onFinish: () => void;
}) {
  const { organizationId: paramsOrganizationId } = useParams();
  const dispatch = useAppDispatch();
  const organizationId = paramsOrganizationId!;
  const provisioningProject = useAppSelector(state =>
    organizationProvisioningProject(state, { organizationId })
  );
  const provisioningFailureProject = useAppSelector(state =>
    organizationProvisioningFailureProject(state, {
      organizationId
    })
  );
  const isOrganizationProjectsLoading = useAppSelector(
    loadingOrganizationProjectsSelector
  );
  const hasProjectProvisioningPullingStarted = useAppSelector(
    organizationProjectProvisioningPullingStarted
  );

  const load = useCallback(() => {
    dispatch(
      getProjects({
        organizationId,
        params: {
          filter: { status: { in: "provisioning,provisioning failure" } }
        }
      })
    );
  }, [dispatch, organizationId]);

  useEffect(() => {
    if (isOrganizationProjectsLoading !== null) return;

    load();
  }, [dispatch, isOrganizationProjectsLoading, load, organizationId]);

  useEffect(() => {
    if (!provisioningProject) return;
    if (hasProjectProvisioningPullingStarted) return;
    dispatch(startProvisioningPulling());

    dispatch(getProject({ organizationId, projectId: provisioningProject.id }));

    const intervalId = setInterval(async () => {
      const project = (
        await dispatch(
          getProject({ organizationId, projectId: provisioningProject.id })
        )
      ).payload as OrganizationProject;

      if (project.status !== "provisioning") {
        dispatch(endProvisioningPulling());
        options?.onFinish();
        clearInterval(intervalId);
      }
    }, 5000);
  }, [
    dispatch,
    hasProjectProvisioningPullingStarted,
    options,
    options?.onFinish,
    organizationId,
    provisioningProject
  ]);

  return { load, provisioningProject, provisioningFailureProject };
}
