import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getOrganizationProjects,
  GetOrganizationProjectsParams
} from "Api/organizations/projects/getOrganizationProjects.request";
import { OrganizationProjectsList } from "Api/organizations/projects/OrganizationProjects.types";
import logger from "Libs/logger";
import { organizationByDescriptionIdSelector } from "Reducers/organization";

import type { AsyncThunkOptionType } from "Reducers/types";

export const getProjects = createAsyncThunk<
  OrganizationProjectsList,
  { organizationId: string; params?: GetOrganizationProjectsParams },
  AsyncThunkOptionType
>(
  "app/organization/get_projects",
  async ({ organizationId, params }, { rejectWithValue, getState }) => {
    try {
      const organization = organizationByDescriptionIdSelector(getState(), {
        organizationDescriptionId: organizationId
      });

      if (!organization) {
        return rejectWithValue("Organization does not exit");
      }

      const projects = await getOrganizationProjects(organization.id, params);

      return projects;
    } catch (err: unknown) {
      const error =
        (err as { title: string })?.title ??
        (err as { description: string })?.description ??
        "An error has occurred";

      logger(err, {
        action: "get_organization_project",
        organizationId
      });

      return rejectWithValue(error);
    }
  }
);
