import { createSelector } from "@reduxjs/toolkit";

import type { RootState } from "Store/configureStore";

export const organizationProjectsStateSelector = (state: RootState) =>
  state.organizationProjects;

export const organizationProjectProvisioningPullingStarted = createSelector(
  organizationProjectsStateSelector,
  organizationProjectsState =>
    organizationProjectsState.hasProvisioningPullingStarted
);

export const loadingOrganizationProjectsSelector = createSelector(
  organizationProjectsStateSelector,
  organizationProjectsState => organizationProjectsState.loading
);

export const organizationProjectSelector = createSelector(
  organizationProjectsStateSelector,
  (_: RootState, params: { organizationId: string; projectId: string }) =>
    params,
  (state, { organizationId, projectId }) =>
    state.data?.[organizationId]?.[projectId]
);

export const organizationProvisioningProject = createSelector(
  organizationProjectsStateSelector,
  (_: RootState, params: { organizationId: string }) => params,
  (state, { organizationId }) =>
    Object.values(state.data?.[organizationId] ?? {}).find(
      project => project.status === "provisioning"
    )
);

export const organizationProvisioningFailureProject = createSelector(
  organizationProjectsStateSelector,
  (_: RootState, params: { organizationId: string }) => params,
  (state, { organizationId }) =>
    Object.values(state.data?.[organizationId] ?? {}).find(
      project => project.status === "provisioning failure"
    )
);
