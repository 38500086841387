import { createSlice } from "@reduxjs/toolkit";

import { setDeep } from "Libs/objectAccess";

import { getProject, getProjects, ProjectsState } from "./index";

const initialState: ProjectsState = {
  data: {},
  hasProvisioningPullingStarted: null,
  loading: null,
  error: null
};

const organizationProjectsReducer = createSlice({
  name: "app/organization/projects",
  initialState,
  reducers: {
    startProvisioningPulling: state => ({
      ...state,
      hasProvisioningPullingStarted: true
    }),
    endProvisioningPulling: state => ({
      ...state,
      hasProvisioningPullingStarted: false
    })
  },
  extraReducers: builder => {
    builder
      // GET PROJECTS
      .addCase(getProjects.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProjects.fulfilled, (state, { meta, payload }) => {
        const { organizationId } = meta.arg;
        const normalizedPayload = Object.fromEntries(
          payload.items.map(project => [[project.id], project])
        );

        setDeep(state, ["data", organizationId], {
          ...state.data[organizationId],
          ...normalizedPayload
        });
        state.loading = false;
      })
      .addCase(getProjects.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      // GET PROJECT
      .addCase(getProject.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProject.fulfilled, (state, { meta, payload }) => {
        const { organizationId, projectId } = meta.arg;

        setDeep(state, ["data", organizationId, projectId], payload);
        state.loading = false;
      })
      .addCase(getProject.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  }
});

export const { startProvisioningPulling, endProvisioningPulling } =
  organizationProjectsReducer.actions;

export default organizationProjectsReducer.reducer;
